<template>
  <v-container fluid no-gutters>
    <B2bTabs/>

    <div class="d-flex justify-end align-center mb-4">
      <v-select
          placeholder="Reseller Type"
          v-model="selectedType"
          item-value="value"
          item-text="name"
          :menu-props="{ bottom: true, offsetY: true }"
          :rules="[(v) => !!v || 'Select a Reseller type']"
          :items="types"
          outlined
          @change="getProducts()"
          background-color="#fff"
          dense
          class="q-autocomplete shadow-0"
          style="max-width: 180px !important;"
          required
          hide-details="auto"
      ></v-select>
    </div>

    <v-card class="mb-8 shadow rounded-3">
      <table class="table text-center table-bordered">
        <thead>
        <tr class="opacity-70 tr-neon tr-rounded text-center font-bold black-text">
          <th class="text-left">Product Name</th>
          <th class="text-center">Status</th>
          <th class="text-center">Original Price</th>
          <th class="text-center">Tax type</th>
          <th class="text-center">Price Pre tax</th>
          <th class="text-center">Price Post tax</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(product, index) in products" :key="index">
          <td class="text-left">{{ product.name }}</td>
          <td>
            <v-btn
                icon
                :color="product.status_id == 1 ? 'success' : 'error'"
                @click="toggleStatus(index)"
            >
              <v-icon>
                {{ product.status_id == 1 ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </v-btn>
          </td>
          <td>
            {{ product.original_price | toCurrency }}
          </td>
          <td style="width:200px;">
            <v-select
                v-if="product.status_id == 1"
                placeholder="Tax*"
                v-model="product.tax_type_id"
                item-value="value"
                item-text="text"
                hint="Required tax"
                style="width: 200px;"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Tax type is required']"
                :items="taxTypes"
                outlined
                hide-details
                @change="taxChange(index)"
                background-color="#fff"
                dense
                class="q-autocomplete shadow-0"
            ></v-select>
            <span v-else>
                {{ product.tax_type }}
              </span>
          </td>
          <td style="width:250px;">
            <v-text-field
                v-if="product.status_id == 1"
                placeholder="Price (Pre Tax)*"
                outlined
                rows="2"
                :prefix="currencyCode"
                background-color="#fff"
                required
                style="width: 80%;"
                hide-details
                v-model="product.price"
                @change="calculateTaxVariation(index, $event, 'pre')"
                :rules="[
                  (v) => {
                    if (v >= 0) {
                      return true;
                    }
                    return 'Price is required';
                  },
                ]"
                dense
                class="q-autocomplete shadow-0"
            ></v-text-field>
            <span v-else>{{ product.price | toCurrency }}</span>
          </td>
          <td style="width:250px;">
            <v-text-field
                v-if="product.status_id == 1"
                placeholder="Price (Post Tax)*"
                outlined
                rows="2"
                :prefix="currencyCode"
                background-color="#fff"
                required
                hide-details
                style="width: 80%;"
                v-model="product.total_price"
                @change="calculateTaxVariation(index, $event, 'post')"
                :rules="[
                  (v) => {
                    if (v >= 0) {
                      return true;
                    }
                    return 'Price is required';
                  },
                ]"
                dense
                class="q-autocomplete shadow-0"
            ></v-text-field>
            <span v-else>{{ product.total_price | toCurrency }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </v-card>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="checkWritePermission($modules.b2b.partner.slug)"
        class="ma-2 white--text teal-color"
        text
        height="45"
        @click="setProducts"
        >Save</v-btn
      >
    </v-card-actions>
  </v-container>
</template>
<script>
import B2bTabs from "@/views/B2b/B2bTabs.vue";

export default {
  components: { B2bTabs},
  mounted() {
    if (!this.$store.getters.configurationStatus("B2B")) {
      this.$store.dispatch("loadConfigurations", "B2B").then(() => {
        this.checkProductEnable();
      });
    } else {
      this.checkProductEnable();
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    this.getProducts();
  },
  computed: {
    b2bConfig() {
      return this.$store.getters.getB2bConfig;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  watch: {},
  data() {
    return {
      types: [
        {
          name: "B2B",
          value: "b2b",
        },
        {
          name: "B2G",
          value: "b2g",
        },
        {
          name: "B2E",
          value: "b2e",
        },
      ],
      selectedType: "b2b",
      allowProducts: 0,
      products: [],
    };
  },
  methods: {
    calculateTaxVariation(index, amount, type) {
      let taxTypeId = this.products[index].tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
          .percentage;
      }

      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.products[index].price = priceData.price.toFixed(2);
        this.products[index].total_price = priceData.total_price.toFixed(2);
      }
      this.$forceUpdate();
    },
    taxChange(index) {
      if (this.products[index].price) {
        this.calculateTaxVariation(index, this.products[index].price, "pre");
      } else if (this.products[index].total_price) {
        this.calculateTaxVariation(
          index,
          this.products[index].total_price,
          "post"
        );
      }
    },
    toggleStatus(index) {
      this.products[index].status_id =
        this.products[index].status_id == 1 ? 2 : 1;
    },
    checkProductEnable() {
      if (this.b2bConfig && this.b2bConfig.enable_specific_products) {
        this.allowProducts = 1;
      } else {
        this.allowProducts = 0;
      }
    },
    getProducts() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/b2b/configuration/products?type=${this.selectedType}`)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.products = response.data.data;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    setProducts() {
      this.showLoader("Saving");
      let products = {
        type: this.selectedType,
        products: this.products,
      };
      this.$http
        .post(`venues/b2b/configuration/products`, products)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.showSuccess("Configuration successfully updated");
            this.getProducts();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
  },
};
</script>
<style>
.cardStyle {
  border: "2px solid #A5A5A5FF";
}
</style>
